<template>
  <v-container fluid class="pa-0 ma-0">
    <v-container fluid class="content">
      <v-row justify="space-between" align="center" class="pa-0 ma-0 mb-2">
        <strong>Hitos de la Ficha de Plan Operativo</strong>
        <div style="display: flex; flex-direction: row; align-items: center;">

          <v-card elevation="0" v-if="clean" class="pr-2">
            <span class="mr-2">¿Seguro?</span>
            <v-btn
              color="green"
              class="primary-text--text mr-2"
              elevation="0"
              small
              @click="resetItem()"
            >
              <small>si</small>
            </v-btn>
            <v-btn
              color="red"
              class="primary-text--text mr-2"
              elevation="0"
              small
              @click="() => { clean = false }"
            >
              <small>no</small>
            </v-btn>
          </v-card>
          <v-btn
            :color="items.length == 0 ? 'green' : 'secondary-color'"
            class="primary-text--text"
            elevation="0"
            @click="addItem()"
          >
            <small><v-icon left small>mdi-plus</v-icon> agregar hito</small>
          </v-btn>
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-card
        elevation="0"
      >
        <v-col cols="12" class="w-full pa-0 ma-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="2">
              <strong>Hito</strong>
            </v-col>
            <v-col cols="3">
              <strong>Actividades</strong>
            </v-col>
            <v-col cols="3">
              <strong>Mes Inicio</strong>
            </v-col>
            <v-col cols="3">
              <strong>Mes de Término</strong>
            </v-col>
            <v-col cols="1">
              <strong></strong>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" v-if="items.length == 0" class="ma-0 pa-0">
            <v-col cols="3">
              <span v-if="!loading">Sin Hitos</span>
              <span v-if="loading">Buscando Hitos ...</span>
            </v-col>
          </v-row>
          <v-row align="center" v-for="(item, itemIndex) in items" :key="itemIndex" class="ma-0 pa-0">
            <v-col cols="2">
              <input-text
                placeholder="Nombre"
                :must="['required']"
                @text="(value) => { items[itemIndex].nombre = value; }"
                :value="items[itemIndex].nombre"
                :hide_details="true"
                @blur="editMilestone(item)"
              />
            </v-col>
            <v-col cols="3">
              <input-text
                placeholder="Actividades"
                :must="['required']"
                @text="(value) => { items[itemIndex].actividad = value; }"
                :value="items[itemIndex].actividad"
                :hide_details="true"
                @blur="editMilestone(item)"
              />
            </v-col>
            <v-col cols="3">
              <select-component
                :items="months"
                itemtext="name"
                @value="(value) => { items[itemIndex].mes_inicio = value; }"
                :default="items[itemIndex].mes_inicio"
                @blur="editMilestone(item)"
              />
            </v-col>
            <v-col cols="3">
              <select-component
                :items="months"
                itemtext="name"
                @value="(value) => { items[itemIndex].mes_termino = value; }"
                :default="items[itemIndex].mes_termino"
                @blur="editMilestone(item)"
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                color="red"
                class="primary-text--text"
                elevation="0"
                small
                @click="removeItem(itemIndex)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-container>
    <v-container class="content-footer pa-0 pt-3 mb-16">
      <v-row justify="space-between" align="center" class="ma-0 pa-0">
        <v-btn
            style="background-color: #EA7730; color: white"
            dark
            class="mr-md-1"
            @click="() => {$router.go(-1)}"
            elevation="0"
            small> <v-icon left small>mdi-arrow-left</v-icon> Volver
        </v-btn>
        <div>
          <small class="mr-4"><b>{{ items.length }} hito{{ items.length == 1 ? '' : 's' }}</b></small>

        </div>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import InputText from '@/components/form/InputText.vue';
import Select from '@/components/form/Select.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'application-form-milestones',
  components: {
    'input-text': InputText,
    'select-component': Select,
  },
  data() {
    return {
      loading: true,
      sent: false,
      clean: false,
      toDelete: [],
      items: [],
      months: [
        {
          name: "Seleccione",
          number: 1000,
        },
        {
          name: 'Enero',
          number: 1,
        },
        {
          name: 'Febrero',
          number: 2,
        },
        {
          name: 'Marzo',
          number: 3,
        },
        {
          name: 'Abril',
          number: 4,
        },
        {
          name: 'Mayo',
          number: 5,
        },
        {
          name: 'Junio',
          number: 6,
        },
        {
          name: 'Julio',
          number: 7,
        },
        {
          name: 'Agosto',
          number: 8,
        },
        {
          name: 'Septiembre',
          number: 9,
        },
        {
          name: 'Octubre',
          number: 10,
        },
        {
          name: 'Noviembre',
          number: 11,
        },
        {
          name: 'Diciembre',
          number: 12,
        },
      ],
      project: null
    }
  },
  created() {
    this.getProject(this.id);
    this.getMilestone(this.id);
  },
  methods: {
    ...mapActions('main', [
      'getProyectoById',
      'getHitoByIdProyecto',
      'createHito',
      'deleteHito',
      'updateHito'
    ]),
    getProject(id) {
      this.getProyectoById(id)
        .then(response => {
          this.project = response.data.response;
        })
        .catch(err => {
          console.log(err);
          this.$router.replace('/home');
        });
    },
    getMilestone(id) {
      this.getHitoByIdProyecto(id)
        .then(response => {
          response.data.response.forEach(milestone => {
            this.items.push({
              id: milestone.id,
              nombre: milestone.nombreHito,
              actividad: milestone.actividades,
              mes_inicio: this.months.find(e => e.name == milestone.mesInicio),
              mes_termino: this.months.find(e => e.name == milestone.mesTermino),
            });
          });
          this.loading = false;
        })
        .catch(err => console.log(err));
    },
    activeClean() {
      this.clean = true;
      setTimeout(() => {
        this.clean = false;
      }, 10000);
    },
    resetItem() {
      this.items.forEach(item => {
        if (item.id != null) {
          this.toDelete.push(item.id);
        }
      });
      this.items = [];
      this.clean = false;
    },
    addItem() {
      this.items.push(
        { 
          id: null,
          nombre: "",
          actividad: "",
          mes_inicio: {name: 'Seleccione', number: 0},
          mes_termino: {name: 'Seleccione', number: 0},
        }
      );
      this.save();
    },
    removeItem(itemIndex) {
      if (this.items[itemIndex].id != null) {
        // this.toDelete.push(this.items[itemIndex].id);
        this.deleteMilestone(this.items[itemIndex].id);
      }
      this.items = this.items.filter((e,i) => {
        e;
        return i != itemIndex;
      });
    },
    save() {
      this.sent = true;
      this.items.forEach((item, index) => {
        if (item.id == null) {
          this.createMilestone(item,index);
        } else {
          this.editMilestone(item);
        }
      });
      this.toDelete.forEach(id => {
        this.deleteMilestone(id);
      });
      this.sent = false;
    },
    deleteMilestone(id) {
      this.deleteHito(id)
        .then(response => console.log(response.data.response))
        .catch(err => console.log(err));
    },
    editMilestone(milestone) {
      this.updateHito({
        id: milestone.id,
        milestone: {
          actividades: milestone.actividad,
          mesInicio: milestone.mes_inicio.name,
          mesTermino: milestone.mes_termino.name,
          nombreHito: milestone.nombre,
        }
      })
        .then(response => console.log(response.data.response))
        .catch(err => console.log(err));
    },
    createMilestone(milestone, index) {
      this.createHito({
        id: this.id,
        milestone: {
          actividades: milestone.actividad,
          mesInicio: milestone.mes_inicio.name,
          mesTermino: milestone.mes_termino.name,
          nombreHito: milestone.nombre,
        }
      })
        .then(response => {
          this.items[index].id = response.data.response.id;
        })
        .catch(err => console.log(err));
    },
  },
  watch: {
    id() {
      this.loading = true;
      this.getProject(this.id);
      this.getMilestone(this.id);
    },
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info']
    }),
    id() {
      return this.$route.params.id;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
}
</script>

<style scoped>
.content {
  background-color: white;
  position: relative;
  width: 90%;
  max-width: 1500px;
  margin-top: 100px;
  border-radius: 10px;
  border: 1px solid #dedede;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.content-footer {
  position: relative;
  width: 90%;
  max-width: 1500px;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.border {
  border: 1px solid #dedede;
  width: 100%;
}
</style>